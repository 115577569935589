@use "../utils/" as *;

.cards {
  display: grid;
  grid-template-columns: 1fr;

  gap: rem(30);

  @include breakpoint(medium) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include breakpoint(large) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
  position: relative;
  background: var(--card-background);
  color: var(--dark-text1);
  text-align: center;
  padding: rem(25);
  border-radius: rem(5);
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background: var(--card-hover);
    transition: background 150ms ease-in-out;
  }

  &--facebook {
    border-top: rem(5) solid var(--facebook);
  }

  &--twitter {
    border-top: rem(5) solid var(--twitter);
  }

  &--instagram {
    padding-top: rem(30);
    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      height: rem(5);
      width: 100%;
      background: linear-gradient(
        225deg,
        var(--instagram-end),
        var(--instagram-middle) 50.91%,
        var(--instagram-start) 100%
      ); // var(--instagram-start);
    }
  }
  &--youtube {
    border-top: rem(5) solid var(--youtube);
  }

  &--grid {
  }

  &__platform {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(28);
    margin-top: rem(5);
    height: rem(20);
  }

  &__subtitle {
    font-size: rem(14);
    font-weight: 700;
  }
  &__icon {
    margin-right: rem(8);
    &--facebook {
    }
    &--twitter {
    }
    &--instagram {
    }
    &--youtube {
    }
  }
  &__username {
    font-size: rem(12);
    font-weight: bold;
  }
  &__followers {
    margin-bottom: rem(25);
  }
  &__count {
    color: var(--light-background);
    font-weight: 700;
    letter-spacing: rem(-2);
    line-height: 1;
    margin-bottom: rem(4);
    &--big {
      font-size: rem(56);
      color: var(--text-color);
    }
    &--small {
      font-size: rem(32);
      color: var(--text-color);
    }
  }
  &__label {
    font-size: rem(12);
    font-weight: 400;
    letter-spacing: rem(5);
    text-transform: uppercase;
    color: var(--dark-text1);
  }
  &__change {
    font-size: rem(12);
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    &--up {
      color: var(--lime-green);
    }
    &--down {
      color: var(--bright-red);
    }
    img {
      margin-right: rem(4);
    }
  }
  &__number {
    margin-right: rem(4);
  }
}
