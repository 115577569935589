:root {
  --lime-green: hsl(163, 72%, 41%);
  --bright-red: hsl(356, 69%, 56%);
  --facebook: hsl(208, 92%, 53%);
  --twitter: hsl(203, 89%, 53%);
  --instagram-start: hsl(37, 97%, 70%);
  --instagram-middle: hsl(5, 77%, 71%);
  --instagram-end: hsl(329, 70%, 58%);
  --youtube: hsl(348, 97%, 39%);
  --toggle-background-light: hsl(230, 22%, 74%);
  --toggle-background-start: hsl(210, 78%, 56%);
  --toggle-background-middle: hsl(5, 77%, 71%);
  --toggle-background-end: hsl(146, 68%, 55%);
  --toggle-light: hsl(230, 19%, 60%);
  --toggle-button-light: hsl(228, 46%, 96%);
  --dark-background: hsl(230, 17%, 14%);
  --dark-top-background: hsl(232, 19%, 15%);
  --dark-card-hover: hsl(228, 25%, 27%);
  --dark-card: hsl(228, 28%, 20%);
  --dark-text1: hsl(228, 34%, 66%);
  --dark-text2: hsl(0, 0%, 100%);

  --light-background: hsl(0, 0%, 100%);
  --light-top-background: hsl(225, 100%, 98%);
  --light-card: hsl(227, 47%, 96%);
  --light-card-hover: hsl(238, 33%, 91%);
  --light-text1: hsl(230, 12%, 44%);
  --light-text2: hsl(230, 17%, 14%);
  --background: var(--light-background);
  --text-color: var(--light-text2);
  --text-color2: var(--light-text1);
  --card-background: var(--light-card);
  --card-hover: var(--light-card-hover);
  --toggle: var(--toggle-light);
  --toggle-background: var(--toggle-background-light);
  --toggle-button: var(--toggle-button-light);
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: var(--dark-background);
    --text-color: var(--dark-text2);
    --text-color2: var(--dark-text1);
    --card-hover: var(--dark-card-hover);
    --card-background: var(--dark-card);
    --toggle: var(--light-background);
    --toggle-background: linear-gradient(
      225deg,
      var(--toggle-background-end) 0%,
      var(--toggle-background-start) 98.02%
    );
    --toggle-button: var(--dark-background);
  }
}

body.dark {
  --background: var(--dark-background);
  --text-color: var(--dark-text2);
  --text-color2: var(--dark-text1);
  --card-hover: var(--dark-card-hover);
  --card-background: var(--dark-card);
  --toggle: var(--light-background);
  --toggle-background: linear-gradient(
    225deg,
    var(--toggle-background-end) 0%,
    var(--toggle-background-start) 98.02%
  );
  --toggle-button: var(--dark-background);
}
body.light {
  --background: var(--light-background);
  --text-color: var(--light-text2);
  --text-color2: var(--light-text1);
  --card-background: var(--light-card);
  --card-hover: var(--light-card-hover);
  --toggle: var(--toggle-light);
  --toggle-background: var(--toggle-background-light);
  --toggle-button: var(--toggle-button-light);
}
